import { Modal } from "react-bootstrap";
import React from "react";
import WebpImage from "../WebpImage";
import NewButton from "../NewButton";
import "./index.scss";

const CheckoutDuplicateModal = ({ show, onHide, onSubmit }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      fullscreen
      centered
      className="duplicate-modal-wrapper"
      dialogClassName="duplicate-modal"
      contentClassName="duplicate-modal-content"
    >
      <Modal.Header closeButton />
      <Modal.Title>Uh oh! It looks like you’ve already enjoyed our $1 offer.</Modal.Title>
      <Modal.Body>
        <p className="description">We’re so happy that you’re loving your Hubble contact lenses. Here’s a special offer — just for you!</p>
        <div className="image-block">
          <div className="tag">50% OFF</div>
          <WebpImage objectFit="contain" fileName="Placeholder/classic-contacts-box.png" />
        </div>
        <p className="price-description">50% OFF Your Next Subscription Order</p>
        <p className="price"><span className="old-price">$19.99/eye</span> $9.99/eye</p>
        <NewButton
          color="black"
          label="Place Order Now"
          className="submit-button"
          onClick={onSubmit}
        />
      </Modal.Body>
    </Modal>
  )
}

export default CheckoutDuplicateModal
