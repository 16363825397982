import React from "react"

import "./style.scss"

const CheckoutRadio = ({ value, onClick, active, type, children }) => {
  const isActive = active && active === value

  return (
    <label
      className="checkout-radio"
      htmlFor={value}
      aria-checked={isActive}
      data-type={type}
    >
      <input
        type="radio"
        value={value}
        id={value}
        onClick={() => onClick(value)}
      />
      {children}
    </label>
  )
}

const Header = ({ label, children }) => (
  <div className="radio-head">
    <div className="indicator-container">
      <span className="circle-icon" />
    </div>
    {!label && children ? (
      <div className="content-container">{children}</div>
    ) : (
      <span className="title text h8 neutral">{label}</span>
    )}
  </div>
)

const Body = ({ children }) => <div className="radio-body">{children}</div>

CheckoutRadio.Header = Header
CheckoutRadio.Body = Body

export default CheckoutRadio
