import React, {useEffect, useState} from 'react';
import './style.scss';

const PreventBottomScroll = ({background = '#e3f7fc', height = '800px', triggerTop = '400px'}) => {
  const isIOS = typeof(navigator) !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (!isIOS || width > 768) return
      const elementTarget = document.getElementById('scroll-trigger')
      const elementScrollY = elementTarget.offsetTop + elementTarget.offsetHeight - window.innerHeight
      if (window.scrollY > elementScrollY) {
        window.scrollTo({top: (elementScrollY - 1), left: 0, behavior: 'instant'});
      };
    }

    if (isIOS) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isIOS])

  if (!isIOS || width > 768) return null

  return (
    <div style={{ height, background, width: '100%' }}>
      <div id="scroll-trigger" style={{ position: 'relative', top: triggerTop }}></div>
    </div>
  )
}

export default PreventBottomScroll