export const lensesData = {
  classic: {
    subProductId: "2167100637257",
    nonSubProductId: "7581064954056",
    nonSubVariantId: "42823273119944",
    nonSubName: "Single Purchase Hubble Daily Contact Lenses",
    subName: "Hubble Daily Contacts 30pk",

  },
  hydro: {
    subProductId: "7546170802376",
    nonSubProductId: "7576426381512",
    nonSubVariantId: "42809597395144",
    nonSubName: "Single Purchase Hydro by Hubble",
    subName: "Hydro by Hubble",
  },
  skyhy: {
    subProductId: "7590722371784",
    nonSubProductId: "7598861320392",
    nonSubVariantId: "42853286281416",
    nonSubName: "Single Purchase SkyHy by Hubble",
    subName: "SkyHy by Hubble",
  },
  "1765080399972": {
    subName: "Acuvue Oasys for Astigmatism 6pk",
    subProductId: "1765080399972"
  },
  "2620861448292": {
    subName: "1-Day Acuvue Moist",
    subProductId: "2620861448292"
  },
  "1765069357156": {
    subName: "Acuvue Oasys 1-Day with Hydraluxe",
    subProductId: "1765069357156"
  },
  "1765114970212": {
    subName: "DAILIES AquaComfort Plus",
    subProductId: "1765114970212"
  },
  "3408938401892": {
    subName: "Biotrue ONEday for Presbyopia",
    subProductId: "3408938401892"
  },
  "1765072797796": {
    subName: "1-Day Acuvue Moist for Astigmatism",
    subProductId: "1765072797796"
  },
  "1765078499428": {
    subName: "Acuvue Oasys with Hydraclear Plus 6pk",
    subProductId: "1765078499428"
  },
  "2620910534756": {
    subName: "DAILIES TOTAL1 90pk",
    subProductId: "2620910534756"
  },
  "1765111332964": {
    subName: "Biotrue ONEday 90pk",
    subProductId: "1765111332964"
  },
}