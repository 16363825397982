import React from "react"

import "./style.scss"
import NewCustomSelect from "../NewCustomSelect"

const CheckoutSelect = ({ label, value, onChange, options, filterOption, placeholder }) => {
  return (
    <div className="checkout-select">
      {label && <p className="title text h8 bulky">{label}</p>}
      <NewCustomSelect value={value} onChange={onChange} options={options} filterOption={filterOption} placeholder={placeholder}/>
    </div>
  )
}

export default CheckoutSelect
